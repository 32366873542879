import { LiqaElement, NAME } from "./wraps/web-component"

export type {
  LiqaElement,
  LiqaAttributes,
  LiqaEventMap,
  ImageCapture,
  CaptureMetadata,
  CaptureTransformations,
  CaptureFormat,
  ErrorCodes,
  LiqaError,
  AnalyticsEventsMap,
  AnalyticsDetails,
} from "./wraps/web-component"
export { Liqa, type LiqaConfig } from "./wraps/factory"

export { preload, type PreloadOptions } from "./app/preload"

/**
 * The library version.
 * @example
 * ```js
 * "6.0.0"
 * ```
 * @category Misc
 */
export const VERSION = import.meta.env.VERSION

/** Register web component and make it available as an HTML tag */
if (!customElements.get(NAME)) customElements.define(NAME, LiqaElement)
