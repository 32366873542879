const preloadedCss = new Set<string>()

/**
 * Preloads the JavaScript module and its CSS dependencies.
 * @param loader - A module loader function, e.g `() => import("./example.js")`
 * @param dependencies - Array of relative URLs of CSS dependencies of the module
 * @param head - The target element to attach the preloaded CSS styles to
 */
export const preload = <T extends () => unknown>(
  loader: T,
  dependencies: string[],
  importerUrl: string,
  head: HTMLElement | ShadowRoot = document.head,
) => {
  /*
   * Implementation idea is borrowed from Vite's module preload helper:
   * https://github.com/vitejs/vite/blob/cc388d9c84e44a9b7d990dfbd3023aaf20494ecd/packages/vite/src/node/plugins/importAnalysisBuild.ts#L74
   */
  const css = Promise.all(
    dependencies.map(
      (dependency) =>
        new Promise<void>((resolve, reject) => {
          if (preloadedCss.has(dependency)) return
          preloadedCss.add(dependency)

          const link = document.createElement("link")
          link.rel = "stylesheet"
          link.href = new URL(dependency, importerUrl).href
          link.addEventListener("load", () => resolve(), { once: true })
          link.addEventListener(
            "error",
            () => reject(new Error(`Unable to preload CSS for ${dependency}`)),
            { once: true },
          )
          head.append(link)
        }),
    ),
  )
  const js = loader()

  return Promise.all([css, js]).then(([, js]) => js as Awaited<ReturnType<T>>)
}
