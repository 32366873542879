/**
 * Transfers CSS dynamically injected by Vite from the `source` to the `target` element.
 *
 * @remark This function is useful to override Vite‘s default behavior of injecting CSS into the `document.head` element
 * and instead inject it into a WebComponent‘s Shadow DOM.
 */
export const adoptStyles = (source: HTMLHeadElement, target: HTMLHeadElement | ShadowRoot) => {
  /* The implementation is inspired by https://github.com/vitejs/vite/issues/11855#issuecomment-1762258886 */

  const isCssLink = (node: Node): node is HTMLLinkElement => {
    if (node.nodeType !== Node.ELEMENT_NODE) return false

    const element = node as Element
    if (element.tagName.toLowerCase() !== "link") return false

    const link = element as HTMLLinkElement
    return link.rel === "stylesheet"
  }

  const isStyleElement = (node: Node): node is HTMLStyleElement => {
    if (node.nodeType !== Node.ELEMENT_NODE) return false

    const element = node as Element
    return element.tagName.toLowerCase() === "style"
  }

  const adopt = (node: Node) => target.append(document.adoptNode(node))

  new MutationObserver((records) => {
    for (const { addedNodes } of records)
      for (const node of addedNodes) {
        if (isStyleElement(node)) adopt(node)
        if (isCssLink(node))
          node.sheet // already loaded
            ? adopt(node)
            : node.addEventListener(
                "load",
                () => {
                  if (!node.isConnected) return // link is removed just after the load, no need to adopt
                  adopt(node)
                },
                { once: true },
              )
      }
  }).observe(source, { childList: true })
}
