/**
 * ShadowRealm is a distinct JavaScript execution context which is isolated from the main context
 * and which has its own and distinct global built-ins like window, document, etc.
 *
 * Grab a full understanding of what the ShadowRealm is from
 * https://www.youtube.com/watch?v=RcWqY4kcjDY
 * and
 * https://github.com/tc39/proposal-shadowrealm
 */

// ShadowRealm naive implementation
export class ShadowRealm {
  private readonly _container: Element | ShadowRoot
  private readonly _iframe = iframe({ name: "ShadowRealm" })

  /**
   * @param container - it‘s crucial to provide some visible container for the iframe so it is treated as visible,
   * see {@link iframe} for details.
   */
  constructor(container: Element | ShadowRoot = document.head) {
    this._container = container
  }

  private async _getGlobal() {
    const iframe = this._iframe

    if (!iframe.isConnected) this._container.appendChild(iframe)

    const contentDocument =
      iframe.contentDocument?.readyState === "complete"
        ? iframe.contentDocument
        : // Firefox requires the iframe element to be fully loaded before appending the script element
          await new Promise<Document>((resolve) =>
            iframe.addEventListener("load", () => resolve(iframe.contentDocument!), {
              once: true,
            }),
          )

    return contentDocument.defaultView!
  }

  /** For usage examples @see https://github.com/tc39/proposal-shadowrealm/blob/main/explainer.md#quick-api-usage-example */
  async importValue<T extends PrimitiveValueOrCallable = any>(
    src: string | URL,
    name: string,
  ): Promise<T> {
    const global = await this._getGlobal()
    const { head } = global.document

    const id = "_" + uid()
    const importee = script(
      `import { ${name} } from "${src}";` +
        `document.head.querySelector("script#${id}").__exports__ = { ${name} };` +
        // https://stackoverflow.com/a/25690050
        `document.head.querySelector("script#${id}").dispatchEvent(new CustomEvent("load"));`,
    ) as HTMLScriptElement & { __exports__: { [key: typeof name]: T } }

    importee.id = id

    await new Promise((resolve) => {
      importee.addEventListener("load", resolve, { once: true })
      head.appendChild(importee)
    })

    return importee["__exports__"][name]
  }
}

const iframe = (attributes: Record<string, string> = {}) => {
  const iframe = document.createElement("iframe")
  Object.assign(iframe, attributes)

  /*
   * Make the iframe visually hidden, but visible for browsers, so they do not treat it as invisible,
   * meaning they do not treat it as an iframe with `document.visibilityState === "hidden"`.
   * https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilityState
   *
   * This is crucial, since documents with `visibilityState === "hidden"` have their timers,
   * such as setTimeout, requestAnimationFrame or video.requestVideoFrameCallback,
   * throttled or even absolutely paused by the browser:
   * https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API#policies_in_place_to_aid_background_page_performance
   */
  Object.assign(iframe.style, {
    position: "absolute",
    width: 0,
    height: 0,
    overflow: "hidden",
    border: "none",
  })

  return iframe
}

const script = (code: string) => {
  const script = document.createElement("script")
  script.type = "module"
  script.textContent = code
  return script
}

const uid = () => Math.random().toString(36).slice(2)

type PrimitiveValueOrCallable =
  | string
  | number
  | boolean
  | symbol
  | bigint
  | ((...args: any[]) => any)
